import axios from "axios";
import { end_points } from "../config";
import { wrapJWT } from "../Plugins/JWT.helper";
import { userDataStore } from "../Plugins/UserData.Store";
import { decrypt } from "./encdec.flux";

const wrapJwtAndConfig = async (token, secretKey) => {
    try {
        const jwtToken = await wrapJWT(token, secretKey);
        return jwtToken;
    } catch (err) {
        console.error("Error while wrapping JWT", err);
        throw new Error("Error while generating JWT: " + err);
    }
};

const performAxiosRequest = async (url, data, config) => {
    try {
        const response = await axios.post(url, data, config);
        const resultobjString = decrypt(
            response?.data?.data,
            userDataStore.getters.envValues.otpEncKey
        );
        response.data = JSON.parse(resultobjString)
        return response.data.fileUrl ?? "";
    } catch (error) {
        let message = "";
        console.error("Axios request failed", error);
        if (error.response) {
            console.error("Response data:", error.response.data);
            console.error("Response status:", error.response.status);
            console.error("Response headers:", error.response.headers);
            message = error.response.statusText || error.response.data || error.response.status;
        } else if (error.request) {
            message = "No response received";
            console.error(message);
        } else {
            console.error("Error in request setup:", error.message);
            if(error.message == 'canceled')
                message = "Cancelled by RE agent";
            else
                message = error.message
        }
        throw new Error("Persist Upload Request failed: " + message);
    }
};

const uploadFile = async ({ url, data, token, requestId, secretKey, abortSignal = null, onUploadProgress = null}) => {
    try {
        let config;

        if (token && requestId) {
            config = {
                onUploadProgress,
                signal: abortSignal,
                headers: {
                    "authorization": token,
                    "temptoken": true,
                    "requestId": requestId,
                }
            };
        } else {
            config = {
                onUploadProgress,
                signal: abortSignal
            };

            try {
                config.headers = {
                    "authorization": await wrapJwtAndConfig(token, secretKey),
                };
            } catch (err) {
                console.error("Error while generating jwt: ", err);
                throw new Error("Error while generating jwt!");
            }
        }

        return performAxiosRequest(url, data, config);
    } catch (error) {
        throw new Error(error);
    }
};

const downloadFile = async (url) => {
  try {
    let userId = userDataStore.getters.userData.userId || "";
    const imageData = await axiosInstance.post(
      end_points.download_files , 
      { url: url, userId: userId } 
    );
    return imageData;
  } catch (error) {
    console.log("Error in downloading the file", error);
    return "";
  }
};

export {
    uploadFile,
    downloadFile
}
